<template>
  <AppBar>

    <FilterMenu
      :value="textFieldsFilter"
      @change="$emit('input:text-fields-filter', $event)"
      :mini="mini"
    />

    <span class="ml-3" />

    <SchoolSubjectSelect
      :school-subjects="schoolSubjects"
      :value="filterBySchoolSubject"
      @input="$emit('input:filter-by-school-subject', $event)"
    />

    <span class="ml-3" />

    <ContextObjectSelect
      v-model="contextObject"
      :loading="loading"
    />

    <DateRangePicker
      v-if="!mini"
      v-model="dateInterval"
      :loading="loading"
      class="ml-4"
    />

  </AppBar>
</template>


<script>
import AppBar from '@/components/app-bar'
import SchoolSubjectSelect from '@/components/school-subject-select'
import ContextObjectSelect from '@/components/context-object-select'
import DateRangePicker from '@/components/date-range-picker'
import FilterMenu from '@/pages/lessons-overview.page/filter-menu'

export default {
  name: 'LessonsOverviewToolbarTop',
  components: {
    AppBar,
    SchoolSubjectSelect,
    ContextObjectSelect,
    DateRangePicker,
    FilterMenu
  },
  props: {
    loading: Boolean,
    mini: Boolean,
    textFieldsFilter: Object,
    schoolSubjects: Array,
    filterBySchoolSubject: Object
  },
  computed: {
    contextObject: {
      get() {
        return this.$store.state.contextObject
      },
      set(contextObject) {
        this.$emit('input:context-object', contextObject)
      }
    },
    dateInterval: {
      get() {
        const { academicYear, section, startDate, endDate } = this.$store.state.schoolCalendar
        return {academicYear, section, startDate, endDate}
      },
      set(value) {
        this.$emit('input:date-interval', value)
      }
    }
  }
}
</script>
