<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        depressed
        height="32"
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          :small="!text"
          :left="!!text"
        >mdi-school-outline</v-icon>
        {{ text }}
      </v-btn>
    </template>

    <v-card max-height="320">
      <v-list dense>
        <v-list-item-group
          :value="value"
          color="primary"
          @change="$emit('input', $event)"
        >
          <v-list-item :value="null">
            <v-list-item-title>Alle Fächer</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="schoolSubject in schoolSubjects"
            :key="schoolSubject['@id']"
            :value="schoolSubject"
          >
            <v-list-item-title>{{ schoolSubject.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-menu>
</template>


<script>
export default {
  name: 'SchoolSubjectSelect',
  props: {
    schoolSubjects: {
      type: Array,
      required: true
    },
    value: Object
  },
  computed: {
    text() {
      return this.value?.code || ''
    }
  }
}
</script>
