<template>
  <div
    class="sticky-header-list-item"
    header-position="top"
  >

    <div class="sticky-header-list-item-body">
      <slot />
    </div>

    <div class="sticky-header-list-item-header">
      <slot name="sticky-header" />
    </div>

  </div>
</template>


<script>
export default {
  name: 'StickyHeaderListItem'
}
</script>


<style lang="scss" scoped>
.sticky-header-list-item {
  padding-top: 24px;

  &:not(:last-child) {
    padding-bottom: 32px;
    margin-bottom: 4px;
  }

  .sticky-header-list-item-header {
    position: absolute;
    height: 24px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    padding: 0 8px;
    line-height: 24px;
    font-size: 14px;
    white-space: nowrap;
    background: #929292;
    color: #fff;
    opacity: 0.8;
  }

  &[header-position="top"] {
    position: relative;

    .sticky-header-list-item-header {
      top: 0;
      border-radius: 6px;
    }
  }

  &[header-position="bottom"] {
    position: relative;

    .sticky-header-list-item-header {
      bottom: 0;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  &[header-position="sticky"] .sticky-header-list-item-header {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
</style>
