<template>
  <div
    class="sticky-header-list overflow-auto"
    @scroll="resetHeadersPosition"
  >
    <slot />
  </div>
</template>


<script>
export default {
  name: 'StickyHeaderList',
  updated() {
    this.resetHeadersPosition()
  },
  methods: {
    resetHeadersPosition() {
      const container = this.$el
      const containerTop = container.offsetTop
      const containerScrollTop = container.scrollTop + containerTop
      const scrollbarWidth = container.offsetWidth - container.clientWidth
      container.childNodes.forEach(childNode => {
        const header = childNode.lastChild
        const { offsetTop, clientHeight } = childNode

        if (containerScrollTop < offsetTop) {
          childNode.setAttribute('header-position', 'top')
          header.style.top = null
          header.style.marginLeft = null
          return
        }

        const bottom = offsetTop + clientHeight - header.clientHeight
        if (containerScrollTop < bottom) {
          childNode.setAttribute('header-position', 'sticky')
          header.style.top = `${containerTop}px`
          header.style.marginLeft = `-${scrollbarWidth / 2}px`
          return
        }

        childNode.setAttribute('header-position', 'bottom')
        header.style.top = null
        header.style.marginLeft = null
      })
    }
  }
}
</script>
