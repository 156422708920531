<template>
  <v-app-bar
    dense
    color="white"
    class="flex-grow-0"
  >
    <DateRangePicker
      v-model="dateInterval"
      :loading="loading"
      class="flex-grow-1"
    />
  </v-app-bar>
</template>


<script>
import DateRangePicker from '@/components/date-range-picker'

export default {
  name: 'LessonsOverviewToolbarBottom',
  components: {
    DateRangePicker
  },
  props: {
    loading: Boolean
  },
  computed: {
    dateInterval: {
      get() {
        const { academicYear, section, startDate, endDate } = this.$store.state.schoolCalendar
        return {academicYear, section, startDate, endDate}
      },
      set(value) {
        this.$emit('input:date-interval', value)
      }
    }
  }
}
</script>
