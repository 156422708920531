<template>
  <component
    offset-y
    min-width="300"
    max-width="430"
    :close-on-content-click="false"
    :is="menuComponent"
    v-model="menu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        depressed
        height="32"
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>mdi-filter-menu-outline</v-icon>
      </v-btn>
    </template>

    <v-card v-if="menu">
      <v-list class="pt-5">

        <v-list-item
          v-for="{ name, label } in textFields"
          :key="name"
        >
          <v-list-item-action>
            <v-checkbox
              :input-value="value[name]"
              @change="$emit('change', {...value, [name]: $event})"
            />
          </v-list-item-action>
          <v-list-item-title>
            {{ label }} anzeigen
          </v-list-item-title>
        </v-list-item>

      </v-list>
    </v-card>
  </component>
</template>


<script>
import { VBottomSheet, VMenu } from 'vuetify/lib'
import { textFields } from '@/entities/actual-lesson'


export default {
  name: 'FilterMenu',
  components: {
    VBottomSheet,
    VMenu
  },
  props: {
    mini: Boolean,
    value: Object
  },
  data: () => ({
    menu: false,
    textFields
  }),
  computed: {
    menuComponent() {
      return this.mini ? VBottomSheet : VMenu
    }
  }
}
</script>
