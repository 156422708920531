<template>
  <div class="lessons-overview-container fill-height d-flex flex-column">

    <ToolbarTop
      :loading="loading"
      :mini="mini"
      :text-fields-filter="textFieldsFilter"
      :school-subjects="schoolSubjects"
      :filter-by-school-subject="filterBySchoolSubject"
      @input:filter-by-school-subject="setFilterBySchoolSubject"
      @input:text-fields-filter="textFieldsFilter = $event"
      @input:context-object="setContextObject"
      @input:date-interval="setDateInterval"
    />

    <StickyHeaderList class="fill-height pa-2 pa-sm-4 pa-lg-8">
      <StickyHeaderListItem
        v-for="{ dateString, rows } in rowsByDay"
        :key="dateString"
      >
        <template v-slot:sticky-header>
          {{ dateString | dateForHumans }}
        </template>
        <v-card
          v-for="{ dayPlanSlot, actualLesson } in rows"
          :key="actualLesson['@id']"
          class="mt-4 pa-1"
        >
          <v-card-title class="flex-nowrap">
            <DayPlanSlotLesson
              :day-plan-slot="dayPlanSlot"
              :actual-lessons="[actualLesson]"
              :context-object="contextObject"
              disabled
            />
            <v-spacer />
            <v-btn
              v-if="canUpdateActualLesson(actualLesson)"
              icon
              @click="toggleEditLessonDialog(actualLesson)"
            >
              <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <LessonTextFields
              :lesson="actualLesson"
              :filter="textFieldsFilter"
            />
          </v-card-text>
        </v-card>
      </StickyHeaderListItem>
    </StickyHeaderList>

    <ToolbarBottom
      v-if="mini"
      :loading="loading"
      @input:date-interval="setDateInterval"
    />

    <EditLessonTextsDialog
      :show="!!editActualLessonDialogValue"
      :actual-lesson="editActualLessonDialogValue || {}"
      @save="updateActualLesson"
      @close="toggleEditLessonDialog(null)"
    />

    <AppLoader v-show="loading" />

  </div>
</template>


<script>
import { mapState, mapGetters } from 'vuex'
import { api, getErrorReason } from '@/api'
import AppLoader from '@/components/app-loader'
import ToolbarTop from '@/pages/lessons-overview.page/toolbar-top'
import ToolbarBottom from '@/pages/lessons-overview.page/toolbar-bottom'
import StickyHeaderList from '@/components/sticky-header-list'
import StickyHeaderListItem from '@/components/sticky-header-list/item'
import DayPlanSlotLesson from '@/components/schedule-day/day-plan-slot-lesson'
import LessonTextFields from '@/components/lesson-text-fields'
import EditLessonTextsDialog from '@/components/edit-lesson-texts-dialog'
import { dateForHumans } from '@/helpers/datetime'
import ActualLesson, { textFields } from '@/entities/actual-lesson'

const groups = ['actual_lesson:full', 'actual_lesson:include_scheduled_lesson']

export default {
  name: 'LessonsOverviewPage',
  components: {
    AppLoader,
    ToolbarTop,
    ToolbarBottom,
    StickyHeaderList,
    StickyHeaderListItem,
    DayPlanSlotLesson,
    LessonTextFields,
    EditLessonTextsDialog
  },
  filters: {
    dateForHumans
  },
  data: () => ({
    loadingActualLessons: null,
    actualLessons: [],
    editActualLessonDialogValue: null,
    textFieldsFilter: Object.fromEntries(textFields.map(({ name }) => [name, true])),
    filterBySchoolSubject: null
  }),
  computed: {
    ...mapGetters(['canUpdateActualLesson']),
    ...mapGetters('common', ['dayPlanSlotByIri']),
    ...mapState(['contextObject']),
    ...mapState('schoolCalendar', [
      'academicYear',
      'section',
      'startDate',
      'endDate'
    ]),
    ...mapState('common', ['schoolSubjects']),
    loading() {
      return this.loadingActualLessons || this.$store.getters['common/loading']
    },
    mini() {
      return this.$vuetify.breakpoint.xsOnly
    },
    rowsByDay() {
      let { actualLessons } = this
      if (this.filterBySchoolSubject) {
        const iri = this.filterBySchoolSubject['@id']
        actualLessons = actualLessons.filter(al =>
          (al.replacement || al.scheduledLesson)?.schoolSubject === iri
        )
      }
      const dates = [...new Set(actualLessons.map(({ date }) => date))]
      return dates.map(dateString => ({
        dateString,
        rows: actualLessons.filter(({ date }) => date === dateString).map(actualLesson => ({
          dayPlanSlot: this.dayPlanSlotByIri(actualLesson.scheduledLesson?.dayPlanSlot),
          actualLesson
        }))
      }))
    },
    showWelcomePage() {
      const state = this.$store.state.common
      return state.academicYears.length == 0
        || state.sections.length == 0
        || state.teachers.length == 0
        || state.classrooms.length == 0
        || state.schoolSubjects.length == 0
    }
  },
  async created() {
    const { dispatch } = this.$store
    await this.loadCommonData()
    if (this.showWelcomePage) {
      this.$router.push({name: 'welcome'})
      return
    }
    await dispatch('schoolCalendar/setNearestAcademicYear')
    await dispatch('schoolCalendar/setNearestSection')
    await Promise.all([
      dispatch('common/fetchSchoolClassesByAcademicYear', this.academicYear),
      dispatch('common/fetchDayPlansBySection', this.section),
      dispatch('common/fetchCoursesBySection', this.section)
    ])
    await dispatch('schoolCalendar/setNearestSchoolDay')
    dispatch('resetContextObject')
    this.loadActualLessons()
  },
  methods: {
    loadCommonData() {
      return this.$store.dispatch('common/fetchCollectionOnce', [
        'academicYears',
        'classrooms',
        'schoolSubjects',
        'sections',
        'teachers'
      ])
    },
    async loadActualLessons() {
      this.actualLessons = []
      const { section, contextObject } = this
      if (!(section && contextObject)) return
      const startDate = this.startDate || section.startsOn
      const endDate = this.endDate || section.endsOn
      const url = `${contextObject['@id']}/actual_lessons`
      const params = {startDate, endDate, groups}
      this.loadingActualLessons = true
      try {
        const response = await api.get(url, {params})
        this.actualLessons = response.data.map(item => new ActualLesson(item))
      } catch (e) {
        this.actualLessons = []
        console.error(e)
      } finally {
        this.loadingActualLessons = false
      }
    },
    async setDateInterval(value) {
      const { commit, dispatch } = this.$store
      const { academicYear, section } = value || {}
      const promises = []
      if (academicYear && academicYear.year !== this.academicYear?.year) {
        promises.push(
          dispatch('common/fetchSchoolClassesByAcademicYear', academicYear)
        )
      }
      if (section && section['@id'] !== this.section?.['@id']) {
        promises.push(
          dispatch('common/fetchDayPlansBySection', section),
          dispatch('common/fetchCoursesBySection', section)
        )
      }
      commit('schoolCalendar/SET_STATE', value)
      await Promise.all(promises)
      dispatch('resetContextObject')
      this.loadActualLessons()
    },
    setContextObject(contextObject) {
      this.$store.commit('SET_CONTEXT_OBJECT', contextObject)
      this.loadActualLessons()
    },
    setFilterBySchoolSubject(schoolSubject) {
      this.filterBySchoolSubject = schoolSubject
    },
    toggleEditLessonDialog(lesson) {
      this.editActualLessonDialogValue = lesson
    },
    async updateActualLesson({ patch, done, error }) {
      try {
        const iri = this.editActualLessonDialogValue['@id']
        const { data } = await api.put(iri, patch, {params: {groups}})
        const actualLessonIndex = this.actualLessons.findIndex(l => l['@id'] === iri)
        if (~actualLessonIndex) {
          this.actualLessons.splice(actualLessonIndex, 1, new ActualLesson(data))
        }
        done()
      } catch (e) {
        console.error(e)
        error(getErrorReason(e))
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.day-plan-slot-lesson {
  max-width: 430px;
}
</style>
